var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(34, 34, 34, 0.27)","overlay-opacity":"0.8","max-width":"520","persistent":""},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[(_vm.dialogLoading)?_c('SkeletonPreloader',{attrs:{"count":1,"type":'list-item-two-line',"width":'100%',"height":'13vh'}}):[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendSMS)}}},[_c('v-card-title',{staticClass:"black--text ft-light text-md"},[_vm._v("Send SMS "),_c('v-btn',{staticClass:"add-payment close-icon",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeDialog($event)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("close")])])],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("alternate_email")]),_c('v-autocomplete',{staticClass:"ft font-weight-bold text-sm",attrs:{"error-messages":errors[0],"autofocus":"","items":_vm.smsIDList,"item-value":"name","item-text":"name","hint":"Provide the sender id here","label":"Sender ID","persistent-hint":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name))])]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"ft font-weight-medium text-sm mr-3"},[_vm._v("No Sender ID Available")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('senderId')}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("add")]),_c('span',{staticClass:"ft pl-1 font-weight-medium"},[_vm._v("New Sender ID")])])],1)]},proxy:true}],null,true),model:{value:(_vm.sms.from),callback:function ($$v) {_vm.$set(_vm.sms, "from", $$v)},expression:"sms.from"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-2",attrs:{"loading":_vm.pageLoading,"disabled":_vm.pageLoading,"icon":"","color":"primary"},on:{"click":_vm.refreshSenderIDList}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Refresh List")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('senderId')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("add")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Add New Sender ID")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("contacts")]),_c('v-text-field',{staticClass:"ft font-weight-bold text-sm",attrs:{"error-messages":errors[0],"readonly":"","hint":"Provide the name of your group here..","label":"Group Contacts","persistent-hint":true},model:{value:(_vm.sms.to),callback:function ($$v) {_vm.$set(_vm.sms, "to", $$v)},expression:"sms.to"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full flex-grow-1 mt-1"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("edit")]),_c('v-textarea',{staticClass:"ft font-weight-medium text-sm",attrs:{"hint":_vm.hint === '' ? "Enter your sms message here" : _vm.hint,"label":"Message","error-messages":errors[0],"persistent-hint":true},model:{value:(_vm.sms.message),callback:function ($$v) {_vm.$set(_vm.sms, "message", $$v)},expression:"sms.message"}})],1)]}}],null,true)}),(
                _vm.totalCreditNeeded >
                (_vm.useIntlRoute
                  ? _vm.creditBalance.intlCredit
                  : _vm.creditBalance.credit)
              )?_c('v-alert',{staticClass:"ft font-weight-medium text-sm ml-9 mt-2",attrs:{"text":"","small":"","dense":"","type":"error"}},[_vm._v("You don't have enough credit in your "+_vm._s(_vm.useIntlRoute ? 'intl acc.' : 'local acc.')+" to send this message.")]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-sm ft text-capitalize",attrs:{"text":"","color":"accent"},on:{"click":_vm.closeDialog}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium text-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading ||
                _vm.totalCreditNeeded >
                  (_vm.useIntlRoute
                    ? _vm.creditBalance.intlCredit
                    : _vm.creditBalance.credit),"color":"primary"}},[_vm._v("Send")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }